import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";

import ErrorPage from "./routes/error";
import Root from "./routes/root";
import Login from "./routes/login";
import DataFollowUpManuell from "./routes/dataFollowupManuall";
import GenerateCTSFormat from "./routes/generateCTSReport";
import APCHelper from "./routes/apcHelper";
import BookingHelper from "./routes/bookinghelper";
import BusParking from "./routes/busParking";
import Start from "./routes/start";
import TicketReport from "./routes/old/ticketReport";
import DrivingReport from "./routes/drivingReport";
import TLSimulator from "./routes/tlSimulator";
import TrafficAreaReport from "./routes/trafficAreaReport";
import MissingTripData from "./routes/missingTripData";
import VehicleDamageReport from "./routes/old/vehicleDamageReport";
import InfoDisplays from "./routes/infoDisplays";
import Users from "./routes/users";
import Logout from "./routes/logout";
import User from "./routes/user";
import TrafficTech from "./routes/trafficTech";
import TrafficTechMessage from "./routes/trafficTechMessage";
import CreateTrafficTech from "./routes/createTrafficTech";
import InfoDisplay from "./routes/infoDisplay";
import Display from "./routes/display";
import Pincode from "./routes/pincode";
import VehicleMap from "./routes/old/vehicleMap";
import RootFull from "./routes/rootFull";
import TripAnalyzer from "./routes/tripAnalyzer";
import InfoPlaylists from "./routes/infoPlaylists";
import InfoPlaylist from "./routes/infoPlaylist";
import DataFollowUp from "./routes/dataFollowup";
import TVA from "./routes/tva";
import TripTinder from "./routes/tripTinder";
import TripMerger from "./routes/tripMerger";
import Edu from "./routes/edu";
import EduTest from "./routes/eduTest";
import TripProgressChecker from "./routes/tripProgressChecker";
import MergeJSONFiles from "./routes/mergeJSONFiles";
import Schedule from "./routes/schedule";
import CreateTimeDistanceGraph from "./routes/createTimeDistanceGraph";
import CreateProductionReport from "./routes/createProductionReport";
import CreateMonthlyProductionReport from "./routes/createMonthlyProductionReport";
import DataMergeVehicle from "./routes/dataMergeVehicle";
import Data574 from "./routes/data574";

const router = createBrowserRouter([
	{
		path: "/",
		element: <Login />,
		errorElement: <ErrorPage />,
	},
	{
		path: "/display/:displayId",
		element: <Display />,
		errorElement: <ErrorPage />,
	},
	{
		path: "/logout",
		element: <Logout />,
	},
	{
		path: "/",
		element: <Root />,
		errorElement: <ErrorPage />,
		children: [
			{
				path: "start",
				element: <Start />,
			},

			{
				path: "datafollowup",
				element: <DataFollowUp />,
			},
			{
				path: "datamergevehicle",
				element: <DataMergeVehicle />,
			},
			{
				path: "data574",
				element: <Data574 />,
			},
			{
				path: "man-datafollowup",
				element: <DataFollowUpManuell />,
			},
			{
				path: "drivingreport",
				element: <DrivingReport />,
			},
			{
				path: "trafficareareport",
				element: <TrafficAreaReport />,
			},
			{
				path: "genctsreport",
				element: <GenerateCTSFormat />,
			},
			{
				path: "apchelper",
				element: <APCHelper />,
			},
			{
				path: "trip-merge",
				element: <TripMerger />,
			},
			{
				path: "bookinghelper",
				element: <BookingHelper />,
			},
			{
				path: "ticketreport",
				element: <TicketReport />,
			},
			{
				path: "missingTripData",
				element: <MissingTripData />,
			},
			{
				path: "vehicledmgreport",
				element: <VehicleDamageReport />,
			},
			{
				path: "infoplaylists",
				element: <InfoPlaylists />,
			},
			{
				path: "infoplaylists/:playlistId",
				element: <InfoPlaylist />,
			},
			{
				path: "infodisplays",
				element: <InfoDisplays />,
			},
			{
				path: "infodisplays/:displayId",
				element: <InfoDisplay />,
			},
			{
				path: "users",
				element: <Users />,
			},
			{
				path: "users/:username",
				element: <User />,
			},
			{
				path: "traffictech",
				element: <TrafficTech />,
			},
			{
				path: "traffictech/create",
				element: <CreateTrafficTech />,
			},
			{
				path: "traffictech/:messageId",
				element: <TrafficTechMessage />,
			},
			{
				path: "pincode",
				element: <Pincode />,
			},
			{
				path: "tva",
				element: <TVA />,
			},
			{
				path: "edu",
				element: <Edu />,
			},
			{
				path: "edu/t/:testId",
				element: <EduTest />,
			},
			{
				path: "tripprogresschecker",
				element: <TripProgressChecker />,
			},
			{
				path: "filemerger",
				element: <MergeJSONFiles />,
			},
			{
				path: "createtimedistancegraph",
				element: <CreateTimeDistanceGraph />,
			},
			{
				path: "schedule",
				element: <Schedule />,
			},
			{
				path: "createProductionReport",
				element: <CreateProductionReport />,
			},
			{
				path: "createMonthlyProductionReport",
				element: <CreateMonthlyProductionReport />,
			},
		],
	},
	{
		path: "/",
		element: <RootFull />,
		errorElement: <ErrorPage />,
		children: [
			{
				path: "vehiclemap",
				element: <VehicleMap />,
			},
			{
				path: "parking",
				element: <BusParking />,
			},
			{
				path: "tripanalyzer",
				element: <TripAnalyzer />,
			},
			{
				path: "tlsimulator",
				element: <TLSimulator />,
			},
			{
				path: "triptinder",
				element: <TripTinder />,
			},
		],
	},
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>,
);
