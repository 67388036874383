import {
	HastusTrip,
	HastusTrips,
	SLReportDataMissing,
	SLReportDataMissingNoResend,
	SLReportDataMissingNoResendTypes,
} from "../types";

import { CallStub, StopTypes } from "../types";
import { formatCTSDate, zeroed } from "./date";
import JSZip from "jszip";

import Vehicle_Ids from "../data/vehicles/vehiclesIds";

import Vehicle_Internal_Ids from "../data/vehicles/vehiclesInternalIds";
import ReroutedTrips from "../data/reroutedTrips";
import { createWBFromRows, createXLSXFile } from "./xlsx";

const lineVariants = ["X", "C", "V", "H", "Y", "Z"];

const EXTRA_TIME_BEOFRE_TRIP = 10;
const EXTRA_TIME_AFTER_TRIP = 35;

export const getHastusTrip = (
	hastusTrip: HastusTrips,
	reportTrip: { line: number; trip: number; date: number, startTime: string, direction: number },
): HastusTrip | null => {
	const { line, trip, date } = reportTrip;
	const key = `${date}-${line}-${trip}`;
	let hastus = hastusTrip[key];

	if (!hastus) {
		for (let i = 0; i < lineVariants.length; i++) {
			const lineVariant = lineVariants[i];
			const key = `${date}-${line + lineVariant}-${trip}`;
			hastus = hastusTrip[key];

			if (hastus) {
				break;
			}
		}
	} 

	// Check if trip id is wrong and use startTime instead.
	const [hour,minute]	= reportTrip.startTime.split(":");
	const specialKey = `${date}-${line}-${reportTrip.direction}${zeroed(parseInt(hour))}${zeroed(parseInt(minute))}`;

	const specialHastus = hastusTrip[specialKey];

	if (specialHastus) {
		return specialHastus;
	}

	if (!hastus) {
		return null;
	}

	return hastus;
};

export const progressGenerator = (call: {
	type: StopTypes;
	LineNameLong: string;
	JourneyName: string;
	Date: string;
	ExternalId: string;
	vehicle: string;
}): CallStub => {
	const data: CallStub = {
		Vehicle: call.vehicle,
		Type: call.type,
		MessageId: crypto.randomUUID(),
		LineNumber: parseInt(call.LineNameLong),
		JourneyNumber: parseInt(call.JourneyName),
		AtDateTime: formatCTSDate(new Date(call.Date)),
		PlannedPointVisitCount: 1,
		ForecastsAreUnreliable: false,
		PlannedPointJourneyPatternPointNumber: parseInt(call.ExternalId),
		ReportTimestamp: formatCTSDate(new Date()),
	};

	if (call.type !== "passage") {
		data.ObservedPointJourneyPatternPointNumber = parseInt(call.ExternalId);
	}

	return data;
};

export const getSQLQueryRecordedTripData = (tripsMissingData: any[]) => {
	let trips = [];

	for (let i = 0; i < tripsMissingData.length; i++) {
		const trip = tripsMissingData[i];
		const year = Math.floor(trip.operatingDay / 10000);
		const month = Math.floor((trip.operatingDay % 10000) / 100);
		const day = trip.operatingDay % 100;

		trips.push(
			`(PJ.[OperatingCalendarDay] = '${year}-${zeroed(month)}-${zeroed(
				day,
			)}' AND Lines.LineNameLong = '${trip.line}' AND PJ.JourneyName = '${trip.trip}')`,
		);
	}

	const query = `
		SELECT PJ.[OperatingCalendarDay],
			PJ.JourneyName,
			Lines.LineNameLong,
			C.PlannedArrivalTime,
			C.PlannedDepartureTime,
			C.ActualArrivalTime,
			C.ActualDepartureTime,
			StopPoints.ExternalId,
			Name AS StopPointName,
			SequenceInJourney,
			VehicleIdentity AS VehicleId,
			Latitude AS StopPointLatitude,
			Longitude AS StopPointLongitude
		FROM [I4M_EXTDB].[dbo].[PlannedJourneys] AS PJ
			JOIN [I4M_EXTDB].[dbo].[Calls] AS C ON PJ.[Id] = C.UsesPlannedJourneyId
			AND PJ.[OperatingCalendarDay] = C.[OperatingCalendarDay]
			JOIN [I4M_EXTDB].[dbo].[Line] AS Lines ON PJ.[BelongsToLineId] = Lines.Id
			AND PJ.[OperatingCalendarDay] = Lines.[OperatingCalendarDay]
			JOIN [I4M_EXTDB].[dbo].[StopPoint] AS StopPoints ON C.UsesStopPointId = StopPoints.Id
			AND PJ.[OperatingCalendarDay] = StopPoints.[OperatingCalendarDay]
		WHERE
			${trips.join(" OR\n")};
	`;

	navigator.clipboard.writeText(query);
};

export const getSQLQueryVehicleHistory = (
	tripsMissingData: any[],
	recordedCTSTrips: any[],
	hastusTrips: any,
) => {
	let trips = [];

	let temp = [];

	let missingTripsMap: any = {};

	for (let i = 0; i < tripsMissingData.length; i++) {
		const trip = tripsMissingData[i];
		const key = createTripKey(trip.operatingDay.toString(), trip.line, trip.trip);
		missingTripsMap[key] = trip;
	}

	// Should loop recordedTripsMissingData. But this is a temp solution..
	// Should loop recordedTripsMissingData. But this is a temp solution..
	// Should loop recordedTripsMissingData. But this is a temp solution..
	// Should loop recordedTripsMissingData. But this is a temp solution..
	// Should loop recordedTripsMissingData. But this is a temp solution..
	for (let i = 0; i < recordedCTSTrips.length; i++) {
		const trip = recordedCTSTrips[i];
		const dateRaw = trip.calls[0].PlannedDepartureTime ?? trip.calls[0].PlannedArrivalTime;

		if (dateRaw === null) {
			console.log("Date is missing", trip);
			continue;
		}

		const date = parseInt(dateRaw.split("T")[0].replaceAll("-", ""));
		const tripKey = createTripKey(trip.calls[0].OperatingCalendarDay, trip.line, trip.trip);

		if (trip.isCompleted) {
			continue;
		}
		const missingTrip = missingTripsMap[tripKey];

		if (!missingTrip) {
			console.log("Vehicle trip is missing 2", trip, missingTrip);
			continue;
		}

		const vehicleTrip =
			hastusTrips[createTripKey(missingTrip.date.toString(), trip.line, trip.trip)];

		if (!vehicleTrip) {
			console.log("Vehicle trip is missing 3", trip, vehicleTrip);
			continue;
		}

		// Check if vehickes is missing.
		if (!vehicleTrip.vehicles) {
			console.log("Vehicle is missing", trip);
			continue;
		}

		const vehicles = vehicleTrip.vehicles
			.split(", ")
			.filter((vehicle: string) => vehicle.length === 4);
		const startYear = Math.floor(date / 10000);
		const startMonth = Math.floor((date % 10000) / 100) - 1;
		const startDay = date % 100;
		const startHour = Math.floor((parseInt(trip.trip) % 10000) / 100);
		const startMinute = parseInt(trip.trip) % 100;
		const start = new Date(
			new Date(startYear, startMonth, startDay, startHour, startMinute).getTime() -
				EXTRA_TIME_BEOFRE_TRIP * 60000,
		);
		const end = new Date(
			start.getTime() + missingTrip.plannedTime * 1000 + EXTRA_TIME_AFTER_TRIP * 60000,
		);

		temp.push({
			vehicles: vehicles
				.map((vehicle: string) => {
					const veh = Vehicle_Ids[vehicle];

					if (!veh) {
						console.log("Vehicle is missing", vehicle, vehicleTrip, missingTrip);
					}

					return veh;
				})
				.filter((vehicle: string) => vehicle),
			start,
			end,
			date: trip.date,
			line: trip.line,
			trip: trip.trip,
		});

		// Loop through vehicleIds.
		for (let j = 0; j < vehicles.length; j++) {
			const vehicleId = Vehicle_Ids[vehicles[j]];

			trips.push(
				`VehicleId = ${vehicleId} AND VehicleTimestamp BETWEEN '${formatCTSDate(
					start,
				)}' AND '${formatCTSDate(end)}'`,
			);
		}
	}

	const query = `
				SELECT
					VehicleTimestamp AS Date,
					VehicleId,
					Latitude,
					Longitude,
					Heading,
					Speed,
					Name AS Event
				FROM [I4M_EXTDB].[Vehicle].[Events] AS Events
					JOIN [I4M_EXTDB].[Vehicle].[Positions] AS Positions ON Events.[Id] = [Positions].EventId
					JOIN [I4M_EXTDB].[Vehicle].[EventTypes] AS EventTypes ON Events.[EventType] = [EventTypes].Id
				WHERE
					${trips.join(" OR\n")};
		`;

	navigator.clipboard.writeText(query);

	return temp;
};

export const createMissingTripDataNoSendRow = (
	operatingDay: any,
	type: SLReportDataMissingNoResendTypes,
	row: any,
): SLReportDataMissingNoResend => ({
	operatingDay,
	line: row["Linje"],
	trip: row["Tur"],
	type,
	completeness: row["Completeness"],
	datedVehicleJourneyGid: row["DatedVehicleJourneyGid"],
});

export const downloadPartialRecordedTrips = async (recordedCTSTrips: any[]) => {
	const res = [];

	for (let i = 0; i < recordedCTSTrips.length; i++) {
		const trip = recordedCTSTrips[i];

		if (trip.isCompleted) {
			continue;
		}

		res.push(...trip.calls);
	}

	const downloadLink = document.createElement("a");

	const blob = new Blob([JSON.stringify(res, null, 4)], {
		type: "application/json",
	});
	downloadLink.href = URL.createObjectURL(blob);
	downloadLink.download = "fpr.json";
	document.body.appendChild(downloadLink);
	downloadLink.click();
	document.body.removeChild(downloadLink);
};

export const downloadAllFinishedTrips = async (
	recordedCTSTrips: any[],
	finishedCreatedTrips: any[],
	hastusTrips: any,
) => {
	const zip = new JSZip();

	for (let i = 0; i < recordedCTSTrips.length; i++) {
		const trip = recordedCTSTrips[i];

		const dateRaw = trip.calls[0].PlannedDepartureTime ?? trip.calls[0].PlannedArrivalTime;

		if (dateRaw === null) {
			console.log("Date is missing", trip);
			continue;
		}

		const date = parseInt(dateRaw.split("T")[0].replaceAll("-", ""));
		const tripKey = `${date}-${trip.line}-${trip.trip}`;

		// Check if trip is completed. If it isnt continue to next trip.
		if (!trip.isCompleted) {
			continue;
		}

		const vehicleTrip = hastusTrips[tripKey];

		if (!vehicleTrip) {
			console.log("Vehicle trip is missing 1", trip);
			continue;
		}

		const stops = trip.calls;

		for (let j = 0; j < stops.length; j++) {
			const stop: any = stops[j];
			// Check if vehickes is missing.
			if (!vehicleTrip.vehicles) {
				console.log("Vehicle is missing or VB", trip);
				continue;
			}

			const vehicle = Vehicle_Internal_Ids[stop.VehicleId];

			let stopTypes: string[] = [];

			// Create progress types.
			if (j === 0) {
				stopTypes = ["departure"];
			} else if (j === stops.length - 1) {
				stopTypes = ["arrival"];
			} else if (stop.ActualArrivalTime && stop.ActualDepartureTime) {
				stopTypes = ["arrival", "departure"];
			} else {
				stopTypes = ["passage"];
			}

			for (let k = 0; k < stopTypes.length; k++) {
				const msg_id = crypto.randomUUID();
				const file_name = `1-45-bus-${vehicle}-progress-${stopTypes[k]}-v1.${msg_id}.txt`;

				const res: any = {
					MessageId: msg_id,
					LineNumber: parseInt(stop.LineNameLong),
					JourneyNumber: parseInt(stop.JourneyName),
					AtDateTime: formatCTSDate(
						new Date(
							stopTypes[k] === "arrival"
								? stop.ActualArrivalTime
								: stop.ActualDepartureTime,
						),
					),
					PlannedPointVisitCount: 1,
					ForecastsAreUnreliable: false,
					PlannedPointJourneyPatternPointNumber: parseInt(stop.ExternalId),
					ReportTimestamp: formatCTSDate(new Date()),
				};

				if (stopTypes[k] !== "passage") {
					res.ObservedPointJourneyPatternPointNumber = parseInt(stop.ExternalId);
				}

				zip.file(file_name, JSON.stringify(res));
			}
		}
	}

	for (let i = 0; i < finishedCreatedTrips.length; i++) {
		const trip = finishedCreatedTrips[i];

		for (let j = 0; j < trip.calls.length; j++) {
			const { Vehicle, Type, ...call } = trip.calls[j];
			const file_name = `1-45-bus-${Vehicle}-progress-${Type}-v1.${call.MessageId}.txt`;

			zip.file(file_name, JSON.stringify(call));
		}
	}

	const content = await zip.generateAsync({ type: "blob" });
	const downloadLink = document.createElement("a");

	downloadLink.href = URL.createObjectURL(content);
	downloadLink.download = "finished-prgresser.zip";
	document.body.appendChild(downloadLink);
	downloadLink.click();
	document.body.removeChild(downloadLink);
};

export const downloadAllCompleteRecordedTrips = async (
	finishedRecorded7OldTrips: any[],
	missedTrips: SLReportDataMissing[],
) => {
	const rows = [];

	// DatedVehicleJourneyGid map.
	const trips_map_datedVehicleJourneyGid: any = {};

	// Loop through all missing trips.
	for (let i = 0; i < missedTrips.length; i++) {
		const trip = missedTrips[i];

		const key = createTripKey(trip.operatingDay.toString(), trip.line, trip.trip);

		trips_map_datedVehicleJourneyGid[key] = trip.datedVehicleJourneyGid;
	}

	const trips = [...finishedRecorded7OldTrips];

	for (let i = 0; i < trips.length; i++) {
		const trip = trips[i];

		// Check if trip is completed. If it isnt continue to next trip.
		if (trip.isCompleted !== undefined && !trip.isCompleted) {
			continue;
		}

		const date = trip.date.split("T")[0].replaceAll("-", "");

		const tripKey = createTripKey(date, trip.line, trip.trip);

		let datedVehicleJourneyGid = trips_map_datedVehicleJourneyGid[tripKey];

		// This should not happen
		if (!datedVehicleJourneyGid) {
			console.log("DatedVehicleJourneyGid is missing", tripKey);

			datedVehicleJourneyGid = "-";
		}
		const row = [date, trip.line, trip.trip, datedVehicleJourneyGid + trip.line + trip.trip];

		const todayTime = new Date().getTime();
		const tripTime = new Date(trip.calls[0].AtDateTime).getTime();

		// If trip is older than 7 days.
		if (todayTime - tripTime > 6 * 24 * 60 * 60 * 1000) {
			row.push("Ja");
		} else {
			row.push("Nej");
		}

		rows.push(row);
	}

	const wb = createWBFromRows(rows, [
		"Datum",
		"Linje",
		"Tur",
		"DatedVehicleJourneyGid",
		"Äldre än 7 dagar",
	]);

	createXLSXFile(wb, "finished_trips.xlsx");
};

export const checkIfReroutedTrip = (call: any) => {
	const { LineNameLong, PlannedDepartureTime, PlannedArrivalTime, ExternalId, StopPointName } =
		call;

	if (ReroutedTrips[LineNameLong]) {
		const rerouted_trip = ReroutedTrips[LineNameLong];
		// Check if stop is rerouted.

		if (rerouted_trip.stops[ExternalId]) {
			const date = PlannedDepartureTime
				? new Date(PlannedDepartureTime).getTime()
				: new Date(PlannedArrivalTime).getTime();

			if (
				new Date(rerouted_trip.start).getTime() <= date &&
				new Date(rerouted_trip.end).getTime() >= date
			) {
				return true;
			}
		}
	}

	return false;
};

export const getDivisionFromString = (string: string) => {
	console.log(string);
	const regexPattern = /^E(?:35|31|38)$/;

	const matchResult = regexPattern.exec(string);

	if (matchResult) {
		return matchResult[1];
	}

	return null;
};

export const createTripKey = (date: string, line: string, trip: string) =>
	`${date.split("T")[0].replaceAll("-", "")}-${line}-${trip}`;

export const downloadVehicleDetails = async (vehicleDetails: any) => {
	const downloadLink = document.createElement("a");

	const blob = new Blob([JSON.stringify(vehicleDetails, null, 4)], {
		type: "application/json",
	});
	downloadLink.href = URL.createObjectURL(blob);
	downloadLink.download = "vd.json";
	document.body.appendChild(downloadLink);
	downloadLink.click();
	document.body.removeChild(downloadLink);
};
