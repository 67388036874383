export const resendReasons = {
	partial: "Trafikerar hela bara om resenär ombord",
	external: "Körs av extern operatör",
	booking: "Anropsstyrdtrafik",
	reinforcement: "Förstärkningstur",
	cancelled: "Del/helinställd tur",
	missingHastus: "Data saknas för att eftersända",
	vehicleMissingHastus: "Data saknas för att eftersända",
	rerouted: "Angör alla hållplatser förutom omlagda",
	missingCTS: "Data saknas för att eftersända",
	resend: "Eftersändning",
	resendCreated: "Eftersändning v2"
};