import * as XLSX from "xlsx";

import { useState } from "react";
import View from "../components/view";
import InputFile from "../components/inputFile";
import Switch from "../components/switch";
import { getDaysToReport, prettyDate, prettyFullDate, getCurrentUTCDateTime } from "../helper/date";
import InputSelectMinmal from "../components/inputSelectMinmal";
import { capitalizeFirstLetter } from "../helper/text";

const sheetNames_match_cateogries: any = {
	dailySummaries: "punctuality",
	h19: "h19",
	partiallyCanceledTraffic: "partiallyCancelled",
	fullyCanceledTraffic: "fullyCancelled",
};

const SUM_HEADER_TITLES = ["Personal", "Teknik", "Yttre"];

const DaysToReport = ({
	value,
	onChange,
}: {
	value: number;
	onChange: (value: number) => void;
}) => {
	const startDate = new Date();

	return (
		<>
			<p className="mt-4 text-sm font-medium">Dagar att rapportera</p>
			<div className="flex w-full">
				{[...Array(31)].map((_, i) => (
					<div
						onClick={() => onChange(i)}
						className={
							" py-0.5 px-1 rounded font-semibold text-sm mr-2 select-none hover:opacity-80 cursor-pointer " +
							(i <= value
								? "bg-blue-200 dark:bg-blue-600 dark:text-black"
								: "bg-slate-100 dark:bg-stone-600 dark:text-black")
						}
						key={"dtp-" + i}
					>
						{prettyDate(startDate.getTime() - (i + 1) * 24 * 3600 * 1000)}
					</div>
				))}
			</div>
		</>
	);
};

export default function CreateProductionReport() {
	const [dataReport, setDataReport] = useState<any>(null);
	const [view, setView] = useState<"file" | "add">("file");

	const [depot, setDepot] = useState("E31");

	const [daysToReport, setDaysToReport] = useState(0);

	const [hasFilledAllReasons, setHasFilledAllReasons] = useState(false);

	const handleTrafficData = (file: any) => {
		const reader = new FileReader();
		reader.onload = (event: any) => {
			// Parse data
			const bstr = event.target.result;

			const wb: any = XLSX.read(bstr, { type: "binary", cellStyles: true, cellDates: true });

			const depots: any = {
				E31: { h19: [], partiallyCancelled: [], fullyCancelled: [], punctuality: [] },
				E35: { h19: [], partiallyCancelled: [], fullyCancelled: [], punctuality: [] },
				E38: { h19: [], partiallyCancelled: [], fullyCancelled: [], punctuality: [] },
			};

			const startDate = new Date();
			startDate.setDate(startDate.getDate() - (daysToReport + 1));
			startDate.setHours(0, 0, 0, 0);

			for (let i = 0; i < wb.SheetNames.length; i++) {
				const ws = wb.Sheets[wb.SheetNames[i]];

				// Convert to JSON and filter out days outside report date
				const rows = XLSX.utils.sheet_to_json(ws).filter((row: any, i) => {
					const date = new Date(row.operatingCalendarDay);

					if (row.contract === "Unknown") {
						return false;
					}

					return startDate <= date;
				});

				for (let j = 0; j < rows.length; j++) {
					const row: any = rows[j];

					const category = sheetNames_match_cateogries[wb.SheetNames[i]];

					row.reason = "";
					row.reasonComment = "";
					row.compass = "";
					row.rt = "";
					row.vehicleId = row.vehicleId ? row.vehicleId : "";

					if (row.line === "574" && wb.SheetNames[i] === "h19") {
						row.reason = "Falsk";
						row.reasonComment = "Falsk";
					}

					if (row.contract === "E31" && category !== "punctuality") {
						row.tl = calculateCancellCreator(
							row.trip,
							new Date(row.operatingCalendarDay),
						);
					}

					depots[row.contract][category].push(row);
				}
			}

			setDataReport(depots);
			setView("add");
		};
		reader.readAsBinaryString(file);
	};

	const handleModifyTrip = (
		depot: string,
		category: string,
		index: number,
		key: string,
		value: string,
	) => {
		const newData = { ...dataReport };
		newData[depot][category][index][key] = value;
		setDataReport(newData);
	};

	const checkIfHasReason = () => {
		if (hasFilledAllReasons) {
			alert("Någon tur saknar anledning. Kontrollera och försök igen.");
			return;
		}
	};

	const createPunciualityString = () => {
		checkIfHasReason();
		// Create string to clipboard to get into excel.
		const data = dataReport[depot].punctuality.map((row: any) => {
			return `${prettyFullDate(new Date(row.operatingCalendarDay).getTime())}\t${
				row.departurePunctuality
			}\t${row.arrivalPunctuality}\t${row.dailyRiders}`;
		});

		navigator.clipboard.writeText(data.join("\n").replaceAll(".", ","));
	};

	const createH19String = () => {
		checkIfHasReason();
		// Create string to clipboard to get into excel.
		// Columns are. Date, Journey, JourneyStart, Line, hastusPermanentNumber, Contact, finalDestionaon, firstAffectedStop, affectedKilometers, affectedMinutes,  reasonComment,reason, compass, vagn,, ec, rt.
		const data = dataReport[depot].h19.map((row: any) => {
			return `${prettyFullDate(new Date(row.operatingCalendarDay).getTime())}\t${
				row.trip
			}\t${getCurrentUTCDateTime(new Date(row.tripStartTime))}\t${row.line}\t${
				row.hastusPermanentNumber
			}\t${row.contract}\t${row.finalDestination}\t${row.firstAffectedStop}\t${
				row.delayAtFirstAffectedStop_minutes
			}\t${row.affectedKilometers}\t${row.affectedMinutes}\t${
				row.reasonComment
			}\t${capitalizeFirstLetter(row.reason)}\t${row.compass}\t${row.vehicleId}\t\t${row.rt}`;
		});

		navigator.clipboard.writeText(data.join("\n").replaceAll(".", ","));
	};

	const calculateCancellCreator = (journeyNumber: number, operatingCalendarDay: Date) => {
		let shortJourneyNumber = parseInt(journeyNumber.toString().substring(1));

		// Check if weekend.
		if (operatingCalendarDay.getDay() === 6 || operatingCalendarDay.getDay() === 0) {
			return "CTL";
		}

		if (shortJourneyNumber > 430 && shortJourneyNumber < 1230) {
			return "FM";
		} else if (shortJourneyNumber > 1230 && shortJourneyNumber < 2030) {
			return "EM";
		} else {
			return "CTL";
		}
	};

	const createCanclledString = (cancellationType: "Hel" | "Del") => {
		checkIfHasReason();
		const selectedData =
			cancellationType === "Hel"
				? dataReport[depot]["fullyCancelled"]
				: dataReport[depot]["partiallyCancelled"];

		const data = selectedData.map((row: any) => {
			let prefix = `${prettyFullDate(new Date(row.operatingCalendarDay).getTime())}\t${
				row.line
			}\t`;
			const reason = capitalizeFirstLetter(row.reason);

			if (depot === "E38") {
				return (
					prefix +
					`${reason}\t${row.trip}\t${cancellationType}\t${row.vehicleId ?? ""}\t${
						row.compass
					}\t${row.reasonComment}\t\t${row.rt}`
				);
			}

			if (depot === "E31") {
				return (
					prefix +
					`${row.trip}\t${cancellationType}\t${reason}${row.tl ?? ""}\t${
						row.vehicleId ?? ""
					}\t${row.compass}\t${row.reasonComment}\t\t${row.rt}`
				);
			}

			if (depot === "E35") {
				return (
					prefix +
					`${row.trip}\t${cancellationType}\t${reason}\t${row.compass}\t${
						row.reasonComment
					}\t\t\t${row.rt}\t${row.vehicleId ?? ""}`
				);
			}

			return "";
		});

		navigator.clipboard.writeText(data.join("\n").replaceAll(".", ","));
	};

	console.log(dataReport);

	const createfullyCancelledString = () => {
		checkIfHasReason();
		const data = dataReport[depot].fullyCancelled.map((row: any) => {
			return `${prettyFullDate(new Date(row.operatingCalendarDay).getTime())}\t${
				row.trip
			}\t${getCurrentUTCDateTime(new Date(row.tripStartTime))}\t${row.line}\t${
				row.hastusPermanentNumber ?? ""
			}\t${depot}\t${row.finalDestination}\t${row.disturbanceDescription ?? ""}\t${
				row.disturbanceReason ?? ""
			}\t${row.disturbanceType ?? ""}\t${row.manuallyCanceledComment ?? ""}\t${
				row.affectedKilometers
			}\t${row.affectedMinutes}\t${capitalizeFirstLetter(row.reason)}`;
		});

		navigator.clipboard.writeText(data.join("\n").replaceAll(".", ","));
	};

	type typeMatrix = {
		partP: number;
		fullP: number;
		partTech: number;
		fullTech: number;
		partOut: number;
		fullOut: number;
		h19: number;
	};

	const createSumForMonth = () => {
		const matrix4d: typeMatrix[] = [];

		const cancelled = [
			...dataReport[depot].partiallyCancelled,
			...dataReport[depot].fullyCancelled,
		];
		const h19 = dataReport[depot].h19;

		const days_devations: any = {};

		for (let i = 0; i < cancelled.length; i++) {
			const date = prettyFullDate(new Date(cancelled[i].operatingCalendarDay).getTime());

			if (days_devations[date] === undefined) {
				days_devations[date] = [];
			}

			days_devations[date].push({
				h19: false,
				full: cancelled[i].firstAffectedStop!,
				reason: cancelled[i].reason,
			});
		}

		for (let i = 0; i < h19.length; i++) {
			const date = prettyFullDate(new Date(h19[i].operatingCalendarDay).getTime());

			if (days_devations[date] === undefined) {
				days_devations[date] = [];
			}

			days_devations[date].push({
				h19: true,
				part: false,
				full: false,
				reason: h19[i].reason,
			});
		}

		const deviationEntries = Object.entries(days_devations);

		for (let i = 0; i < deviationEntries.length; i++) {
			const deviationEntry = deviationEntries[i];

			const date = deviationEntry[0];
			const deviations = deviationEntry[1] as any[];

			const matrix4dRow = {
				partP: 0,
				fullP: 0,
				partTech: 0,
				fullTech: 0,
				partOut: 0,
				fullOut: 0,
				h19: 0,
			};

			for (let j = 0; j < deviations.length; j++) {
				const deviation = deviations[j];
				const reason = deviation.reason.toLowerCase();

				if (reason === "falsk") {
					continue;
				}

				if (deviation.h19) {
					matrix4dRow.h19++;
					continue;
				}

				switch (reason) {
					case "yttre":
						if (deviation.full) {
							matrix4dRow.fullOut++;
						} else {
							matrix4dRow.partOut++;
						}
						break;
					case "teknik":
						if (deviation.full) {
							matrix4dRow.fullTech++;
						} else {
							matrix4dRow.partTech++;
						}
						break;
					case "personal":
						if (deviation.full) {
							matrix4dRow.fullP++;
						} else {
							matrix4dRow.partP++;
						}
						break;
					default:
						console.log("Unknown reason", deviation.reason);
						break;
				}
			}

			matrix4d.push(matrix4dRow);
		}

		return matrix4d;
	};

	const createSumForMonthCopyString = () => {
		checkIfHasReason();
		const matrix4d = createSumForMonth();

		const data = matrix4d.map((row) => {
			return `${row.fullP}\t${row.partP}\t${row.fullTech}\t${row.partTech}\t${row.fullOut}\t${
				row.partOut
			}\t${row.h19}`;
		});

		navigator.clipboard.writeText(data.join("\n"));
	};

	const createPartiallyCancelledString = () => {
		const data = dataReport[depot].partiallyCancelled.map((row: any) => {
			return `${prettyFullDate(new Date(row.operatingCalendarDay).getTime())}\t${
				row.trip
			}\t${getCurrentUTCDateTime(new Date(row.tripStartTime))}\t${row.line}\t${
				row.hastusPermanentNumber ?? ""
			}\t${depot}\t${row.finalDestination}\t${row.firstAffectedStop}\t${
				row.disturbanceDescription ?? ""
			}\t${row.disturbanceReason ?? ""}\t${row.disturbanceType ?? ""}\t${
				row.affectedKilometers
			}\t${row.affectedMinutes}\t${capitalizeFirstLetter(row.reason)}`;
		});

		navigator.clipboard.writeText(data.join("\n").replaceAll(".", ","));
	};

	return (
		<div className="flex flex-1 pt-2 w-full flex-col">
			<h1 className="text-2xl font-bold mb-4 mt-10">
				Generera rader för roduktionsuppföljning
			</h1>

			<View show={view === "file"}>
				<p className="mt-4 text-sm font-medium">Ladda upp excel-fil</p>
				<InputFile accept=".xlsx" name="asdasd" onHandleFile={handleTrafficData} />
				<DaysToReport value={daysToReport} onChange={setDaysToReport} />
			</View>

			<View show={view === "add"}>
				<div className="flex w-full justify-between flex-row">
					<div className="flex "><p className="flex text-lg font-semibold">{depot}</p><p className="flex text-lg font-semibold">{getDaysToReport(daysToReport)}</p></div>
					<div className="flex self-end flex-col items-end">
						<Switch items={[ "E31","E38", "E35"]} value={depot} onChange={setDepot} />
					</div>
				</div>

				{dataReport && dataReport[depot] ? (
					<div className="flex w-full mt-6">
						<div className="flex w-full flex-col">
							<div className="flex w-full flex-row justify-between">
								<h3 className="font-semibold mr-4">Punktlighet</h3>
								<div
									onClick={() => createPunciualityString()}
									className="bg-stone-100 px-2 py-1 rounded hover:text-stone-500 transition-colors cursor-pointer"
								>
									Kopiera punktlighet
								</div>
							</div>
							<div className="flex w-full flex-row gap-2">
								{dataReport[depot]?.punctuality.map((row: any, i: number) => {
									return (
										<div
											key={"punctuality-" + i}
											className="flex flex-col bg-slate-100 min-w-32 py-1 px-3 rounded"
										>
											<div className="font-semibold">
												{prettyDate(
													new Date(row.operatingCalendarDay).getTime(),
												)}
											</div>
											<div className="">
												Avgång {row.departurePunctuality}
											</div>
											<div className="">
												Ankomst: {row.arrivalPunctuality}
											</div>
											<div className="">Passagerare {row.dailyRiders}</div>
										</div>
									);
								})}
							</div>
							<div className="flex w-full flex-col mt-6">
								<div className="flex w-full flex-row justify-between mb-2">
									<h3 className="font-semibold mr-4">H19</h3>
									{dataReport[depot]?.h19.length !== 0 ? (
										<div
											onClick={() => createH19String()}
											className="bg-stone-100 px-2 py-1 rounded hover:text-stone-500 transition-colors cursor-pointer"
										>
											Kopiera H19
										</div>
									) : null}
								</div>
								<div className="flex w-full flex-col">
									{dataReport[depot]?.h19.length !== 0 ? (
										dataReport[depot]?.h19.map((row: any, i: number) => {
											return (
												<div
													key={"h19-" + i}
													className="flex flex-row justify-between mb-1 bg-slate-100 py-1 px-2 rounded"
												>
													<div>
														<div className="flex">
															<h2 className="font-semibold text-lg">
																{prettyDate(
																	new Date(
																		row.operatingCalendarDay,
																	).getTime(),
																)}
															</h2>
															<p className="ml-2 text-lg">
																{row.vehicleId} {row.line}/
																{row.trip} {row.finalDestination}
															</p>
															{row.tl ? (
																<p className="ml-1 px-2 py-0 font-semibold text-sm leading-7 rounded">
																	{row.tl}
																</p>
															) : null}
														</div>
														<p>
															{row.firstAffectedStop} (
															{Number(
																row.delayAtFirstAffectedStop_minutes,
															).toFixed(2)}
															min)
														</p>
													</div>
													<div className="flex items-center">
														<InputSelectMinmal
															placeholder="Orsak"
															value={row.reason}
															items={[
																["falsk", "Falsk"],
																["yttre", "Yttre"],
																["personal", "Personal"],
																["teknik", "Teknik"],
															]}
															onChange={(value: any) =>
																handleModifyTrip(
																	depot,
																	"h19",
																	i,
																	"reason",
																	value,
																)
															}
														/>
														<input
															value={row.reasonComment}
															placeholder="Orsak kommentar"
															onChange={(event) =>
																handleModifyTrip(
																	depot,
																	"h19",
																	i,
																	"reasonComment",
																	event.target.value,
																)
															}
															className="bg-white border ml-1 border-slate-200 py-2 px-2 appearance-none cursor-pointer disabled:text-gray-500 dark:bg-stone-900 rounded dark:border-stone-900 dark:text-stone-200 dark:placeholder:text-stone-400"
														/>
														<input
															value={row.compass}
															placeholder="Compass ID"
															onChange={(event) =>
																handleModifyTrip(
																	depot,
																	"h19",
																	i,
																	"compass",
																	event.target.value,
																)
															}
															className="bg-white border ml-1 border-slate-200 py-2 px-2 w-32 appearance-none cursor-pointer disabled:text-gray-500 dark:bg-stone-900 rounded dark:border-stone-900 dark:text-stone-200 dark:placeholder:text-stone-400"
														/>
														<input
															value={row.rt}
															placeholder="Störningsnummer"
															onChange={(event) =>
																handleModifyTrip(
																	depot,
																	"h19",
																	i,
																	"rt",
																	event.target.value,
																)
															}
															className="bg-white border ml-1 border-slate-200 py-2 px-2 w-32 appearance-none cursor-pointer disabled:text-gray-500 dark:bg-stone-900 rounded dark:border-stone-900 dark:text-stone-200 dark:placeholder:text-stone-400"
														/>
														{/* <div
															className="flex whitespace-nowrap bg-white text-red-500 px-2 py-2 rounded ml-1 font-medium hover:opacity-80 cursor-pointer"
															onClick={() =>
																removeTrip(
																	depot,
																	"partiallyCancelled",
																	i
																)
															}
														>
															Ta bort
														</div> */}
													</div>
												</div>
											);
										})
									) : (
										<p className="font-medium text-stone-500 text-sm">
											Inga turer
										</p>
									)}
								</div>
							</div>
							<div className="flex w-full flex-col mt-6">
								<div className="flex w-full flex-row justify-between">
									<h3 className="font-semibold mb-1">Delvis inställd trafik</h3>
									{dataReport[depot]?.partiallyCancelled.length !== 0 ? (
										<div className="flex flex-row mb-2">
											<div
												onClick={() => createCanclledString("Del")}
												className="bg-stone-100 px-2 py-1 mr-2 rounded hover:text-stone-500 transition-colors cursor-pointer"
											>
												Kopiera till <i>Inställda</i>
											</div>
											<div
												onClick={() => createPartiallyCancelledString()}
												className="bg-stone-100 px-2 py-1 rounded hover:text-stone-500 transition-colors cursor-pointer"
											>
												Kopiera till <i>Del</i>
											</div>
										</div>
									) : null}
								</div>
								<div className="flex w-full flex-col">
									{dataReport[depot]?.partiallyCancelled.length !== 0 ? (
										dataReport[depot]?.partiallyCancelled.map(
											(row: any, i: number) => {
												return (
													<div
														key={"partiallyCancelled-" + i}
														className="flex flex-row justify-between mb-1 bg-slate-100 py-1 px-2 rounded"
													>
														<div>
															<div className="flex">
																<h2 className="font-semibold text-lg">
																	{prettyDate(
																		new Date(
																			row.operatingCalendarDay,
																		).getTime(),
																	)}
																</h2>
																<p className="ml-2 text-lg">
																	{row.vehicleId} {row.line}/
																	{row.trip}{" "}
																	{row.finalDestination}
																</p>
																{row.tl ? (
																	<p className="ml-1 px-2 py-0 font-semibold text-sm leading-7 rounded">
																		{row.tl}
																	</p>
																) : null}
															</div>
															<p>{row.firstAffectedStop}</p>
														</div>
														<div className="flex items-center">
															<input
																value={row.vehicleId}
																placeholder="Vagn"
																onChange={(event) =>
																	handleModifyTrip(
																		depot,
																		"partiallyCancelled",
																		i,
																		"vehicleId",
																		event.target.value,
																	)
																}
																className="bg-white border ml-1 border-slate-200 py-2 px-2 appearance-none cursor-pointer w-24 disabled:text-gray-500 dark:bg-stone-900 rounded dark:border-stone-900 dark:text-stone-200 dark:placeholder:text-stone-400"
															/>
															<InputSelectMinmal
																placeholder="Orsak"
																value={row.reason}
																items={[
																	["falsk", "Falsk"],
																	["yttre", "Yttre"],
																	["personal", "Personal"],
																	["teknik", "Teknik"],
																]}
																onChange={(value: any) =>
																	handleModifyTrip(
																		depot,
																		"partiallyCancelled",
																		i,
																		"reason",
																		value,
																	)
																}
															/>
															<input
																value={row.reasonComment}
																placeholder="Orsak kommentar"
																onChange={(event) =>
																	handleModifyTrip(
																		depot,
																		"partiallyCancelled",
																		i,
																		"reasonComment",
																		event.target.value,
																	)
																}
																className="bg-white border ml-1 border-slate-200 py-2 px-2 appearance-none cursor-pointer disabled:text-gray-500 dark:bg-stone-900 rounded dark:border-stone-900 dark:text-stone-200 dark:placeholder:text-stone-400"
															/>
															<input
																value={row.compass}
																placeholder="Compass ID"
																onChange={(event) =>
																	handleModifyTrip(
																		depot,
																		"partiallyCancelled",
																		i,
																		"compass",
																		event.target.value,
																	)
																}
																className="bg-white border ml-1 border-slate-200 w-32 py-2 px-2 appearance-none cursor-pointer disabled:text
													:500 dark:bg-stone-900 rounded dark:border-stone-900 dark:text-stone-200 dark:placeholder:text-stone-400"
															/>
															<input
																value={row.rt}
																placeholder="Störningsnummer"
																onChange={(event) =>
																	handleModifyTrip(
																		depot,
																		"partiallyCancelled",
																		i,
																		"rt",
																		event.target.value,
																	)
																}
																className="bg-white border ml-1 w-32 border-slate-200 py-2 px-2 appearance-none cursor-pointer disabled:text
													:500 dark:bg-stone-900 rounded dark:border-stone-900 dark:text-stone-200 dark:placeholder:text-stone-400"
															/>
															{/* <div
																className="flex whitespace-nowrap bg-white text-red-500 px-2 py-2 rounded ml-1 font-medium hover:opacity-80 cursor-pointer"
																onClick={() =>
																	removeTrip(
																		depot,
																		"partiallyCancelled",
																		i
																	)
																}
															>
																Ta bort
															</div> */}
														</div>
													</div>
												);
											},
										)
									) : (
										<p className="font-medium text-stone-500 text-sm">
											Inga turer
										</p>
									)}
								</div>
							</div>
							<div className="flex w-full flex-col mt-6">
								<div className="flex w-full flex-row justify-between">
									<h3 className="font-semibold mb-1">Helt inställd trafik</h3>
									{dataReport[depot]?.fullyCancelled.length !== 0 ? (
										<div className="flex flex-row mb-2">
											<div
												onClick={() => createCanclledString("Hel")}
												className="bg-stone-100 px-2 py-1 mr-2 rounded hover:text-stone-500 transition-colors cursor-pointer"
											>
												Kopiera till <i>Inställda</i>
											</div>
											<div
												onClick={() => createfullyCancelledString()}
												className="bg-stone-100 px-2 py-1 rounded hover:text-stone-500 transition-colors cursor-pointer"
											>
												Kopiera till <i>Hel</i>
											</div>
										</div>
									) : null}
								</div>
								<div className="flex w-full flex-col">
									{dataReport[depot]?.fullyCancelled.length !== 0 ? (
										dataReport[depot]?.fullyCancelled.map(
											(row: any, i: number) => {
												return (
													<div
														key={"fullyCancelled-" + i}
														className="flex flex-row justify-between mb-1 bg-slate-100 py-1 px-2 rounded"
													>
														<div>
															<div className="flex">
																<h2 className="font-semibold text-lg">
																	{prettyDate(
																		new Date(
																			row.operatingCalendarDay,
																		).getTime(),
																	)}
																</h2>
																<p className="ml-2 text-lg">
																	{row.vehicleId} {row.line}/
																	{row.trip}{" "}
																	{row.finalDestination}
																</p>
																{row.tl ? (
																	<p className="ml-1 px-2 py-0 font-semibold text-sm leading-7 rounded">
																		{row.tl}
																	</p>
																) : null}
															</div>
														</div>
														<div className="flex items-center">
															<InputSelectMinmal
																placeholder="Orsak"
																value={row.reason}
																items={[
																	["falsk", "Falsk"],
																	["yttre", "Yttre"],
																	["personal", "Personal"],
																	["teknik", "Teknik"],
																]}
																onChange={(value: any) =>
																	handleModifyTrip(
																		depot,
																		"fullyCancelled",
																		i,
																		"reason",
																		value,
																	)
																}
															/>
															<input
																value={row.reasonComment}
																placeholder="Orsak kommentar"
																onChange={(event) =>
																	handleModifyTrip(
																		depot,
																		"fullyCancelled",
																		i,
																		"reasonComment",
																		event.target.value,
																	)
																}
																className="bg-white border ml-1 border-slate-200 py-2 px-2 appearance-none cursor-pointer disabled:text-gray-500 dark:bg-stone-900 rounded dark:border-stone-900 dark:text-stone-200 dark:placeholder:text-stone-400"
															/>
															<input
																value={row.compass}
																placeholder="Compass ID"
																onChange={(event) =>
																	handleModifyTrip(
																		depot,
																		"fullyCancelled",
																		i,
																		"compass",
																		event.target.value,
																	)
																}
																className="bg-white border ml-1 w-32 border-slate-200 py-2 px-2 appearance-none cursor-pointer disabled:text
													:500 dark:bg-stone-900 rounded dark:border-stone-900 dark:text-stone-200 dark:placeholder:text-stone-400"
															/>
															<input
																value={row.rt}
																placeholder="Störningsnummer"
																onChange={(event) =>
																	handleModifyTrip(
																		depot,
																		"fullyCancelled",
																		i,
																		"rt",
																		event.target.value,
																	)
																}
																className="bg-white border ml-1 border-slate-200 w-32 py-2 px-2 appearance-none cursor-pointer disabled:text
													:500 dark:bg-stone-900 rounded dark:border-stone-900 dark:text-stone-200 dark:placeholder:text-stone-400"
															/>
															{/* <div
																className="flex whitespace-nowrap bg-white text-red-500 px-2 py-2 rounded ml-1 font-medium hover:opacity-80 cursor-pointer"
																onClick={() =>
																	removeTrip(
																		depot,
																		"partiallyCancelled",
																		i
																	)
																}
															>
																Ta bort
															</div> */}
														</div>
													</div>
												);
											},
										)
									) : (
										<p className="font-medium text-stone-500 text-sm">
											Inga turer
										</p>
									)}
								</div>
							</div>
							<div className="flex w-full flex-col mt-6">
								<div className="flex w-full justify-between">
									<h3 className="font-semibold mb-1">Sammanställning</h3>
									<div className="flex flex-row mb-2">
										<div
											onClick={() => createSumForMonthCopyString()}
											className="bg-stone-100 px-2 py-1 mr-2 rounded hover:text-stone-500 transition-colors cursor-pointer"
										>
											Kopiera månadens<i> sammanställning</i>
										</div>
									</div>
								</div>
								<div className="flex w-full justify-between flex-col">
									<div className="flex w-full justify-between">
										{SUM_HEADER_TITLES.map((item) => (
											<div className="w-40 flex flex-col justify-center items-center">
												<p className="font-medium text-center">{item}</p>
												<div className="flex flex-row w-full justify-between">
													{["Hel", "Del"].map((type) => (
														<p className="w-20 text-center">{type}</p>
													))}
												</div>
											</div>
										))}
										<p className="font-medium">
											{depot === "E31" ? "H19" : "D19"}
										</p>
									</div>
									<div className="flex w-full justify-between flex-col">
										{createSumForMonth().map((day) => (
											<div className="flex w-full flex-row justify-between">
												<div className="w-40 flex flex-row justify-between items-center">
													<p>{day.fullP}</p>
													<p>{day.partP}</p>
												</div>
												<div className="w-40 flex flex-row justify-between items-center">
													<p>{day.fullTech}</p>
													<p>{day.partTech}</p>
												</div>
												<div className="w-40 flex flex-row justify-between items-center">
													<p>{day.fullOut}</p>
													<p>{day.partOut}</p>
												</div>
												<div className="w-40 flex flex-row justify-between items-center">
													<p>{day.h19}</p>
												</div>
											</div>
										))}
									</div>
								</div>
							</div>
						</div>
					</div>
				) : null}
			</View>
		</div>
	);
}
