import * as XLSX from "xlsx";

import { useState } from "react";
import View from "../components/view";
import InputFileMulti from "../components/inputFileMulti";
import { getDaysToReport } from "../helper/date";
import InputFile from "../components/inputFile";
import { parseTripsCSVWithKeys } from "../helper/csv";
import { parseJSON } from "../helper/storage";

const HEADERS_PUNCTUALITY = ["Månad", "Kontrakt", "Avgång", "Ankomst"];

const HEADERS_H19 = [
	"Avtal",
	"Datum",
	"Starttid",
	"Tur",
	"Linje",
	"FordonsID",
	"Från HPL",
	"SlutHPL",
	"Minuter sen",
	"Påverkade km",
	"Påverkad tid",
	"Störningsnummer",
	"Orsak",
	"Intern kommentar",
	"Gulmarkerad kommentar",
];

const HEADERS_PARTIALLY_CANCELED = [
	"Avtal",
	"Trafikdatum",
	"Starttid",
	"Tur",
	"Linje",
	"FordonsID",
	"Från HPL",
	"Till HPL",
	"SlutHPL",
	"Tur KM",
	"Påverkade km",
	"Påverkade minuter",
	"TLÅ",
	"Störningsnummer",
	"Intern kommentar",
	"Orsak",
	"% inställt",
	"Kommentar TLÅ",
	"Gulmarkerad kommentar",
];

const HEADERS_FULLY_CANCELED = [
	"Avtal",
	"Trafikdatum",
	"Starttid",
	"Tur",
	"Linje",
	"SlutHPL",
	"Påverkade km",
	"Påverkade minuter",
	"TLÅ",
	"Störningsnummer",
	"Intern kommentar",
	"Orsak",
	"Kommentar TLÅ",
	"Gulmarkerad kommentar",
];

const HEADER_NAMES_H19S = {
	E31: {
		date: "operatingCalendarDay",
		startTime: "tripStartTime",
		trip: "trip",
		line: "line",
		vehicle: "Vagn",
		from: "firstAffectedStop",
		to: "finalDestination",
		delay: "delayAtFirstAffectedStop_minutes",
		affectedKM: "affectedKilometers",
		affectedMinutes: "affectedMinutes",
		issue: "Störning",
		cause: "Orsak",
		comment: "Kommentar",
	},
	REST: {
		date: "operatingCalendarDay",
		startTime: "journeyStartTime",
		trip: "journeyName",
		line: "lineNameLong",
		vehicle: "Vagn",
		from: "c_firstAffectedStop",
		to: "c_finalDestinationName",
		delay: "c_delayAtFirstAffectedStop_minutes",
		affectedKM: "c_affectedKilometers",
		affectedMinutes: "c_affectedMinutes",
		issue: "Störning",
		cause: "Orsak",
		comment: "Kommentar",
	},
};

const LINES_WITH_VARIANTS: any = {
	640: ["640", "640Z"],
	623: ["623V", "623H"],
	670: ["670", "670X"],
	676: ["676", "676X"],
	538: ["538", "538H", "538V"],
	571: ["571", "571X"],
	624: ["624", "624C"],
	628: ["628", "628C"],
	622: ["622", "622X"],
};

const MONTHS = [
	"Januari",
	"Februari",
	"Mars",
	"April",
	"Maj",
	"Juni",
	"Juli",
	"Augusti",
	"September",
	"Oktober",
	"November",
	"December",
];

const ALL_DIVISIONS = [
	{ division: "Märsta", contract: "E31" },
	{ division: "Täby", contract: "E35" },
	{ division: "Norrtälje", contract: "E38" },
];

const HEADER_NAMES_PUNCTUALITY: any = {
	E31: {
		date: "Datum",
		depature: "Starthållplats",
		arrival: "Sluthållplats",
	},
	E35: {
		date: "Datum",
		depature: "Starthållplats",
		arrival: "Sluthållplats",
	},
	E38: {
		date: "operatingCalendarDay",
		depature: "departurePunctuality",
		arrival: "arrivalPunctuality",
	},
};

const HEADER_NAMES_CANCELED = {
	E31: {
		date: "operatingCalendarDay",
		startTime: "tripStartTime",
		trip: "trip",
		line: "line",
		finalDestination: "finalDestination",
		affectedKM: "affectedKilometers",
		affectedMinutes: "affectedMinutes",
		issue: "Störning",
		cause: "Orsak",
	},
	REST: {
		date: "operatingCalendarDay",
		startTime: "journeyStartTime",
		trip: "journeyName",
		line: "lineNameLong",
		finalDestination: "c_finalDestinationName",
		affectedKM: "c_affectedKilometers",
		affectedMinutes: "c_affectedMinutes",
		issue: "Störning",
		cause: "Orsak",
	},
};

const MonthsToReport = ({
	value,
	onChange,
}: {
	value: number;
	onChange: (value: number) => void;
}) => {
	const startDate = new Date();

	return (
		<>
			<p className="mt-4 text-sm font-semibold">Månad att rapportera</p>
			<div className="flex w-full flex-wrap gap-2">
				{[...Array(7)].map((_, i) => {
					const tempDate = new Date(startDate);
					tempDate.setMonth(startDate.getMonth() - i);

					return (
						<div
							onClick={() => onChange(i)}
							className={
								" py-0.5 px-6 rounded font-semibold text-sm select-none hover:opacity-80 cursor-pointer whitespace-nowrap " +
								(i === value
									? "bg-blue-200 dark:bg-blue-600 dark:text-black"
									: "bg-slate-100 dark:bg-stone-600 dark:text-black")
							}
							key={"dtp-" + i}
						>
							{MONTHS[tempDate.getMonth()]} {tempDate.getFullYear()}
						</div>
					);
				})}
			</div>
		</>
	);
};

const generateMonthPunctuality = (report: any[], contract: string) => {
	const stats: any = {
		depature: 0,
		arrival: 0,
	};

	let monthYear = "";

	// Add each day to the stats.
	for (let i = 0; i < report.length; i++) {
		const row = report[i];

		if (monthYear === "") {
			const date = new Date(row[HEADER_NAMES_PUNCTUALITY[contract].date]);
			monthYear = `${MONTHS[date.getMonth()]}-${date.getFullYear().toString().substr(2, 4)}`;
		}

		stats.depature += row[HEADER_NAMES_PUNCTUALITY[contract].depature];
		stats.arrival += row[HEADER_NAMES_PUNCTUALITY[contract].arrival];
	}

	return [
		monthYear,
		contract,
		Number(stats.depature / report.length).toFixed(2),
		Number(stats.arrival / report.length).toFixed(2),
	];
};

const findContract = (fileName: string) => {
	const division = ALL_DIVISIONS.find((contract) => fileName.includes(contract.division));
	return division ? division.contract : null;
};

const filterData = (report: any, selectedDate: Date, key = "operatingCalendarDay") =>
	report.filter((row: any) => {
		const date = new Date(row[key]);

		return (
			new Date(row[key]).getMonth() === selectedDate.getMonth() &&
			new Date(row[key]).getFullYear() === selectedDate.getFullYear()
		);
	});

const createTripMap = (trips: any[]) => {
	const tripMap: any = {};

	for (let i = 0; i < trips.length; i++) {
		const trip = trips[i];
		tripMap[formatDate(trip.Datum) + trip.Linje + trip.Turnr] = trip;
	}

	return tripMap;
};

const formatDate = (date: Date) => new Date(date).toLocaleDateString("sv-SE");

const formatStartTime = (date: string) => {
	let realDate = date;

	// If date is a string, convert it to a date.
	if (typeof date === "string") {
		const [d, time] = date.split(" ");

		realDate = `${d} ${time}`;
	}

	return new Date(realDate).toTimeString().split(" ")[0].split(":").slice(0, 2).join(":");
};

const generateH19 = (h19s: any[], contract: string, hastusTrips: any, disturbanceData: any) => {
	const formattedH19s: any = [];

	const HEADERS = HEADER_NAMES_H19S[contract === "E31" ? "E31" : "REST"];

	for (let i = 0; i < h19s.length; i++) {
		const h19 = h19s[i];
		const tripKey = formatDate(h19[HEADERS.date]) + h19[HEADERS.line] + h19[HEADERS.trip];
		const disturbance = disturbanceData[tripKey];

		formattedH19s.push([
			contract,
			formatDate(h19[HEADERS.date]),
			formatStartTime(h19[HEADERS.startTime]), // Starttid
			h19[HEADERS.trip],
			h19[HEADERS.line],
			h19[HEADERS.vehicle] ? h19[HEADERS.vehicle] : disturbance ? disturbance.vehicle : "",
			h19[HEADERS.from],
			h19[HEADERS.to],
			Number(h19[HEADERS.delay]).toFixed(2),
			Number(h19[HEADERS.affectedKM]).toFixed(2),
			Number(h19[HEADERS.affectedMinutes]).toFixed(2),
			h19[HEADERS.issue] ? h19[HEADERS.issue] : disturbance ? disturbance : "",
			h19[HEADERS.cause],
			h19[HEADERS.comment],
			"",
		]);
	}

	return formattedH19s;
};

const getLineAndTrip = (journeyTrip: string) => {
	const [line, trip] = journeyTrip.split("-");
	return [line, trip];
};

const generateCanceled = (
	canceled: any[],
	contract: string,
	partiallyCancelled: any[],
	fullyCanceled: any[],
	hastusTrips: any,
	disturbanceData: any,
) => {
	const formattedPartiallyCanceled: any = [];
	const formattedFullyCanceled: any = [];

	const canceledMap = createTripMap(canceled);

	const HEADERS = HEADER_NAMES_CANCELED[contract === "E31" ? "E31" : "REST"];

	for (let i = 0; i < partiallyCancelled.length; i++) {
		const row = partiallyCancelled[i];
		const mappedCanceled =
			canceledMap[`${formatDate(row[HEADERS.date])}${row[HEADERS.line]}${row[HEADERS.trip]}`];
		const comment = mappedCanceled ? mappedCanceled["Kommentar"] : "";

		const tripKey = `${formatDate(row[HEADERS.date])}${row[HEADERS.line]}${row[HEADERS.trip]}`;
		const trip = hastusTrips[`${row[HEADERS.line]}${row[HEADERS.trip]}`];
		const disturbance = disturbanceData[tripKey];

		formattedPartiallyCanceled.push([
			contract,
			formatDate(row[HEADERS.date]),
			formatStartTime(row[HEADERS.startTime]),
			row[HEADERS.trip],
			row[HEADERS.line],
			disturbance?.vehicle ? disturbance.vehicle : "", // Vehicle
			disturbance?.first_hpl ? disturbance.first_hpl : "", // First affected stop.
			disturbance?.last_hpl ? disturbance.last_hpl : "", // Last affected stop.
			row[HEADERS.finalDestination],
			trip?.toString(), // Planned KM.
			Number(row[HEADERS.affectedKM]).toFixed(2),
			Number(row[HEADERS.affectedMinutes]).toFixed(2),
			"Delinställd",
			row[HEADERS.issue] ? row[HEADERS.issue] : disturbance ? disturbance : "", // Störningsnummer
			comment,
			row[HEADERS.cause],
			"", // % inställt
			"", // Kommentar TLÅ
			"", // Gulmarkerad kommentar
		]);
	}

	for (let i = 0; i < fullyCanceled.length; i++) {
		const row = fullyCanceled[i];
		const tripKey = formatDate(row[HEADERS.date]) + row[HEADERS.line] + row[HEADERS.trip];
		const mappedCanceled = canceledMap[tripKey];
		const comment = mappedCanceled ? mappedCanceled["Kommentar"] : "";

		formattedFullyCanceled.push([
			contract,
			formatDate(row[HEADERS.date]),
			formatStartTime(row[HEADERS.startTime]),
			row[HEADERS.trip],
			row[HEADERS.line],
			row[HEADERS.finalDestination],
			Number(row[HEADERS.affectedKM]).toFixed(2),
			Number(row[HEADERS.affectedMinutes]).toFixed(2),
			"Helinställd",
			row[HEADERS.issue] ? row[HEADERS.issue] : disturbanceData[tripKey] ?? "", // Störningsnummer
			comment,
			row[HEADERS.cause],
			"", // Kommentar TLÅ
			"", // Gulmarkerad kommentar
		]);
	}

	return [formattedPartiallyCanceled, formattedFullyCanceled];
};

const sortRows = (rows: any[]) =>
	rows.sort((a, b) => new Date(a[1]).getTime() - new Date(b[1]).getTime());

export default function CreateMonthlyProductionReport() {
	const [dataReport, setDataReport] = useState<any>(null);
	const [hastusTrips, setHastusTrips] = useState<any>({});
	const [disturbanceData, setDisturbanceData] = useState<any>({});
	const [view, setView] = useState<"file" | "add">("file");
	const [monthToReport, setMonthToReport] = useState(1);

	const handleHastusData = (file: any) => {
		const reader = new FileReader();
		reader.onload = (evt: any) => {
			// Parse data
			const bstr = evt.target.result.replaceAll("\r", "");

			const rows = bstr.split("\n");

			const tripsMap: any = {};

			for (let i = 0; i < rows.length; i++) {
				const row = rows[i].split(";").map((r: string) => r.trim());

				tripsMap[row[1] + row[2]] = row[5];
			}

			setHastusTrips({ ...tripsMap });
		};
		reader.readAsBinaryString(file);
	};

	const handleDisturbanceData = (file: any) => {
		const reader = new FileReader();
		reader.onload = (evt: any) => {
			// Parse dat

			const bstr = evt.target?.result;

			const wb: any = XLSX.read(bstr, {
				type: "binary",
				cellStyles: true,
				cellDates: true,
			});

			const newTrips: any = XLSX.utils.sheet_to_json(wb.Sheets["Traffic Changes"], {
				defval: "",
			});

			const trips: any = {};

			for (let i = 0; i < newTrips.length; i++) {
				// Check if journey exists.
				if (!newTrips[i].Journey) {
					continue;
				}

				const journeys = newTrips[i].Journey.split(",");

				for (let j = 0; j < journeys.length; j++) {
					const [line, trip] = getLineAndTrip(journeys[j]);

					const LINE_VARIANTS = LINES_WITH_VARIANTS[line] ?? [line];

					for (let k = 0; k < LINE_VARIANTS.length; k++) {
						const created_split = newTrips[i]["Created"].split(" ");
						const valid_from_split = newTrips[i]["Valid From"].split(" ");

						let date = "";

						// Check if diff between valid and created is less than 4 hours.
						if (
							Math.abs(
								new Date(`${created_split[0]} ${created_split[1]}`).getTime() -
									new Date(
										`${valid_from_split[0]} ${valid_from_split[1]}`,
									).getTime(),
							) <
							7 * 24 * 60 * 60 * 1000
						) {
							date = newTrips[i]["Valid From"].split(" ")[0];
						} else {
							date = newTrips[i]["Created"].split(" ")[0];
						}

						const tripKey = `${date}${LINE_VARIANTS[k]}${trip}`;

						trips[tripKey] = newTrips[i]["Reference Id"];
					}
				}
			}

			setDisturbanceData({ ...trips });
		};
		reader.readAsBinaryString(file);
	};

	const handleReport = (files: FileList) => {
		let tempState: Promise<any>[] = [];

		const tempDate = new Date();
		tempDate.setMonth(tempDate.getMonth() - monthToReport);
		const selectedDate = tempDate;

		Array.from(files).map((file: File) => {
			const reader = new FileReader();

			const contentPromise = new Promise<any>((resolve) => {
				reader.onload = (event) => {
					// Parse data
					const bstr = event.target?.result;

					const wb: any = XLSX.read(bstr, {
						type: "binary",
						cellStyles: true,
						cellDates: true,
					});

					// Get which contract the report is from.
					const contract = findContract(file.name);

					if (!contract) {
						// console.error("Could not find contract for file: ", file.name);
						alert("Kunde inte hitta kontraktet för filen: " + file.name);
						return;
					}

					const rawPartiallyCanceled = filterData(
						XLSX.utils.sheet_to_json(wb.Sheets["Del"], {
							defval: "",
						}),
						selectedDate,
					);

					const rawFullyCanceled = filterData(
						XLSX.utils.sheet_to_json(wb.Sheets["Hel"], {
							defval: "",
						}),
						selectedDate,
					);

					const [partiallyCanceled, fullyCanceled] = generateCanceled(
						filterData(
							XLSX.utils.sheet_to_json(wb.Sheets["Inställda"], {
								defval: "",
							}),
							selectedDate,
							"Datum",
						),
						contract,
						rawPartiallyCanceled,
						rawFullyCanceled,
						hastusTrips,
						disturbanceData,
					);

					const punctuality = generateMonthPunctuality(
						filterData(
							XLSX.utils.sheet_to_json(wb.Sheets["Punktlighet"], {
								defval: "",
							}),
							selectedDate,
							contract === "E38" ? "operatingCalendarDay" : "Datum",
						),
						contract,
					);

					const h19 = generateH19(
						filterData(
							XLSX.utils.sheet_to_json(wb.Sheets["H19"], {
								defval: "",
							}),
							selectedDate,
						),
						contract,
						hastusTrips,
						disturbanceData,
					);

					resolve({
						punctuality,
						h19,
						partiallyCanceled,
						fullyCanceled,
					});
				};
			});
			tempState.push(contentPromise);
			reader.readAsBinaryString(file);
		});

		Promise.all(tempState).then((promises: any) => {
			const temp_all_punctuality: any[] = [];
			const temp_all_h19: any[] = [];
			const temp_all_partiallyCanceled: any[] = [];
			const temp_all_fullyCanceled: any[] = [];

			promises.map((promise: any) => {
				temp_all_punctuality.push(promise.punctuality);
				temp_all_h19.push(...promise.h19);
				temp_all_partiallyCanceled.push(...promise.partiallyCanceled);
				temp_all_fullyCanceled.push(...promise.fullyCanceled);
			});

			/* setDataReport({
                punctuality: temp_all_punctuality,
                h19: temp_all_h19,
                partiallyCanceled: temp_all_partiallyCanceled,
                fullyCanceled: temp_all_fullyCanceled,
            }); */

			const wb = XLSX.utils.book_new();
			const wsPunctuality = XLSX.utils.aoa_to_sheet([
				HEADERS_PUNCTUALITY,
				...temp_all_punctuality,
			]);
			const wsH19 = XLSX.utils.aoa_to_sheet([HEADERS_H19, ...sortRows(temp_all_h19)]);
			const wsPartiallyCanceled = XLSX.utils.aoa_to_sheet([
				HEADERS_PARTIALLY_CANCELED,
				...sortRows(temp_all_partiallyCanceled),
			]);
			const wsFullyCanceled = XLSX.utils.aoa_to_sheet([
				HEADERS_FULLY_CANCELED,
				...sortRows(temp_all_fullyCanceled),
			]);

			XLSX.utils.book_append_sheet(wb, wsPunctuality, "Punktlighet");
			XLSX.utils.book_append_sheet(wb, wsH19, "H19");
			XLSX.utils.book_append_sheet(wb, wsPartiallyCanceled, "Delinställda");
			XLSX.utils.book_append_sheet(wb, wsFullyCanceled, "Helinställda");

			XLSX.writeFile(
				wb,
				"Produktionsunderlag " +
					MONTHS[selectedDate.getMonth()] +
					"-" +
					selectedDate.getFullYear().toString() +
					".xlsx",
			);
		});
	};

	return (
		<div className="flex flex-1 pt-2 w-full flex-col">
			<h1 className="text-2xl font-bold mb-4 mt-10">Skapa produktionsunderlag</h1>

			<View show={view === "file"}>
				<p className="mt-4 text-sm font-semibold">Ladda upp Hastus turdistanser</p>
				<InputFile accept=".exp" name="asdasd" onHandleFile={handleHastusData} />

				<p className="mt-4 text-sm font-semibold">Ladda upp CTS störningsinformation</p>
				<InputFile accept=".xlsx" name="asdasd" onHandleFile={handleDisturbanceData} />

				<p className="mt-8 text-sm font-semibold">
					Ladda upp alla divisioners produktionsrapporter
				</p>
				<InputFileMulti accept=".xlsx" name="asdasd" onHandleFile={handleReport} />
				<MonthsToReport value={monthToReport} onChange={setMonthToReport} />
			</View>

			<View show={view === "add"}>
				<div className="flex w-full justify-between flex-row">
					<p className="flex text-lg font-semibold">{getDaysToReport(monthToReport)}</p>
					<div className="flex self-end flex-col items-end"></div>
				</div>
			</View>
		</div>
	);
}
